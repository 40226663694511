* {
    font-family: sans-serif, 'Josefin Sans';
}

p {
    font-size:large;
    font-family: 'Josefin Sans', sans-serif;;
}


.container h2 {
    text-align: center;
}


h2 {
    font-size: 50px;
}

body {
    background-color: rgba(34,38,41,255);
    color: #F9EAE1;
}


/* about */

.headerName {

}

.projectTitle h2 {
    font-size: 330px;

}


/* Portfolio */
.githubLink {
    float: right;
    font-size: larger;
}

.projectLink {
    font-size: larger;
}

.single-project {
    margin: 10px;
    padding: 10px;
}

.projectTitle {
    margin-left: 7.7%;
}

.projectsPage {
    margin-top: 1%;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Resume */
.resumePage {
    margin-left: 5%;
    margin-right: 5%px;
}

/* header  */
.header {
    background: rgba(34,38,41,255);
    height: 50px;
    display: flex;
    flex-direction: row;
    font-family: 'Josefin Sans', sans-serif;


}
  
.header h1 {
margin: 0;
padding-top: 10px;
text-align: center;
color: #F9EAE1;
font-size: 25px;
}

.header-bar {
    width: 100%;
}

a {
    color: #F9EAE1;

}
  
/* .nav .nav-tabs {
    display: inline-block;
    float: right;
} */

/* Contact */
.contact-form {
    display: flex;
    flex-direction: column;
}

/* navtabs */
.navigationBar {
    float: right;
}

.navigation {
    width: 60%;

    padding-top: 6%;
    padding-left: 30%;

}

.projectTitle {
    align-self: center;
}

.nav-link {
    font-size: 25px;
    /* font-weight: bold; */
}

.main-holder {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.main-header {
    padding-left: 5%;
    margin-right: 50%;
}

.linked-sites {
    /* display: flex;
    justify-content: flex-end; */
    position:absolute; 
    right:2%;
    display: block;
    padding: .5rem 1rem;
}
.list-inline {
    margin-top: 10px;
}

/* footer */
.footer {
    /* position: absolute;
    bottom: 0; */
    width: 100%;
    max-height: 10%;
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
    margin-top: 1%;
}

.githubLogo {
    margin-right: 2%;
}

.footLink {
    max-height: 3%;
    max-width: 3%;
}


