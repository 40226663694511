.contact-form {
    display: flex;
    flex-direction: column;
}

.confirmationText {
    margin: auto;
    width: 50%;
    padding: 10px;
    font-size: 40px;
}

.btn .btn-primary {
    margin-bottom: 3%;
}

.container {
    margin-top: 1%;
}
